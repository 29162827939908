

export const Card = ({header, content, price, icon}) => {

  return (
    <div class="tm-col column-card">
      <div class="card-content-container">
        <div class="tm-section card-icon-row">
          <img class="card-icon" src={icon} alt="library"/>
        </div>
        
        <h2 class="card-header"> {header} </h2>
       
        <p class="card-content">{content}</p>
       
        <h5 class="card-price">Hinta: {price}</h5>
        
        
      
      </div> 
    </div>
  )

}