import { Card } from "./Card"
import { Parallax } from "react-parallax"


export const Services = () => {

  return (
    <div id="services" class="tm-section">
            
      <div class="tm-section-col tm-content">
          <h2 class="tm-text-primary content-center">Palvelut</h2>
          <p>   
            Annan ohjausta henkilökohtaisesti lähitapaamisena Tampereella tai Teamsin välityksellä.
            Voit varata ajan yksittäiselle koulutus- ja uraohjaustapaamiselle tai voit hankkia itsellesi ”Oma opo”- palvelun koko vuodeksi, jolloin voit saada vuoden ajan ohjausta niin usein kuin koet itse tarpeelliseksi.
          </p>
                         
          <div class="tm-row">
            <Card 
                header="Yksittäiset ohjauskerrat"
                content="Ohjauksen kesto n. 1,5-2h. Seuraavat käynnit 9kk:n aikana puoleen hintaan."
                price="100€"
                icon="../icons/library.png"
            />
            <Card 
                header="Oma opo -palvelu"
                content="Rajaamaton määrä henkilökohtaista ohjausta, lähitapaamisia, Teamsin välityksellä, sähköpostilla…
                Ota yhteyttä niin usein, kun sinulla on tarvetta."
                price="200€/vuosi" 
                icon= "../icons/education.png"
            />
            
          </div>                
      </div>   

      <Parallax bgImage="img/img_8.jpeg"  strength={500} >    
          <img class="tm-section-col tm-parallax" src="img/img_8.jpeg" alt="intro"></img>
      </Parallax>
    </div> 
  )
}
