
export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content flex">
        <img className="copyright-icon" src="../icons/copyright.png" alt="c"></img>
        <span>startura </span> 
      </div>
      <div className="footer-content tm-paging tm-text-small"> Web design by Konsta Puranen</div>
    </div>
  )
}