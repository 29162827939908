import {Intro} from "./Intro"
import { Services } from "./Services"
import { Contact } from "./Contact"
import { Footer } from "./Footer"



export const Content = () => {

  return(
  <>
    <main>

        <Intro/>
                 
        <Services/>
   
        <Contact />
        <Footer/>
            
    </main>
  </>
  )
}

/*
        <div>
            <img class="bottom-image" src="img/img_12.jpg" alt="bottom"/>
      </div>   
      
      */ 