
import { Parallax } from "react-parallax"

export const Intro = () => {
  const img1 = "../img/img_3.jpeg"
  const img2 = "../img/img_10.jpeg"
  const text = `
    Oletko juuri nyt kilpaurheilun huippuvaiheessa vai ehkä siirtymässä pois aktiiviselta kilpaurheilu-uraltasi?
    Olet ehkä miettinyt opiskelun aloittamista? 

     Ohjauspalveluni on tarkoitettu juuri sinulle.
    Kartoitetaan yhdessä juuri sinun urheilu-uraan ja elämäntilanteeseen sopivat vaihtoehdot opiskelun aloittamiseksi. `

  return (
    
    <div id="intro" class="tm-section">
          
      <div class="tm-section-col tm-content tm-small"> 

        <h1 class="page-header">startura</h1>
        <h3 class="page-header-small"> koulutus- ja uraohjauspalvelu <br></br> aikuisille urheilijoille</h3>

        <p class="tm-mb-20 tm-text-small intro-text">
          Oletko urheilija, joka haluaisi aloittaa opiskelun? 
        </p>
        <p class="tm-mb-20 tm-text-small intro-text"> 
          Olet ehkä keskittynyt viime vuosina pääasiassa urheiluun ja opiskeluajoistasi voi olla jo aikaa. Nyt olisi aikaa opiskelulle, mutta et tiedä mitä kaikkia mahdollisuuksia sinulle on tarjolla.
        </p>

        <p class="tm-mb-20 tm-text-small intro-text">
          Ohjauspalveluni on tarkoitettu juuri sinulle.
        </p>
        <p class="tm-mb-20 tm-text-small intro-text">
          Kartoitetaan yhdessä elämäntilanteeseesi sopivat vaihtoehdot. 
          Saat konkreettista apua myös itse hakuprosessiin.          
        </p>

        

      </div>  

      <Parallax bgImage="img/img_5.jpeg" strength={500} >    
          <img class="tm-section-col tm-parallax" src="img/img_5.jpeg" alt="intro"></img>
      </Parallax>
        
         
    </div> 
  )
}
//<img class="tm-section-col tm-parallax" src="img/img_5.jpeg" alt="Intro"/> 
