import { Parallax } from "react-parallax"

export const Contact = () => {

  return (
    <div id="contact" class="tm-section">
          
        <div class="tm-section-col tm-content tm-content-small">
            <div> 
              <h2 className="tm-text-primary content-center">Minusta</h2>
              
              <p> Minulla on yli 20 vuoden kokemus ohjauksesta eri ikäisten kanssa. Yli 10 vuotta olen ohjannut eri lajien urheilijoita kaksoisuran tukemiseksi.
                  Olen Suomen opinto-ohjaajat ry:n jäsen. Ohjauksessa noudatan SOPO ry:n hyväksymiä <a className="hyperlink" href="https://www.sopo.fi/yhdistys/eettiset-periaatteet/" rel="noreferrer" target="_blank">ohjaustyön eettisiä periaatteita.</a>
              </p>
            </div>
            <div >
              <div>
                <h2 class="tm-text-primary content-center">Ota yhteyttä</h2>
              </div>

              <div class="flex contact-container">
                <div className="photo-container">
                  <img class="contact-photo" src="../img/JB2.jpg" alt="" />
                </div>
                
                
                <div class="content-center align-center">
                  <div>
                    <p class="zero-margin"> Opinto-ohjaaja Jaana Bamberg</p>  

                    <div class="contact-phone-mail">
                      <img class="small-icon" src="../icons/call.png" alt="phone"/>
                      <p class="zero-margin">0407245285</p>
                    </div>

                    <div class="contact-phone-mail">
                      <img class="small-icon" src="../icons/mail.png" alt="phone"/>
                      <p class="zero-margin">etunimi.sukunimi@startura.fi</p>
                    </div>
                  </div>                  
                </div>
               
              </div>
 
            </div>
                    
        </div>
            
        
        <Parallax bgImage="img/img_9.jpeg" strength={500} className="parallax-container" >    
          <img class="tm-section-col tm-parallax" src="img/img_9.jpeg" alt="contact"></img>
        </Parallax>
    </div>     
  )
}
//<img class="tm-section-col tm-parallax"  src="img/img_13.jpg" alt="contact"/>