import './styles/magnific-popup.css';
import './styles/tooplate-vertex.css';
import { Content } from './components/Content';
import { Navbar } from './components/Navbar';


function App() {

  
  return (
    <div className="App">
      
        <Navbar/>
        <Content/>
    </div>
  );
}

export default App;
