import React from "react";
import { useEffect, useState } from "react";

export const Navbar = () => {
    const [activeSection, setActiveSection] = useState('intro');

    useEffect(() => {
        const handleScroll = () => {
          // Determine which section is in view based on scroll position
          // You may need to adjust these values based on your layout
          const servicesOffset = document.getElementById('services').offsetTop;
          //const pricesOffset = document.getElementById('experiences').offsetTop;
          const contactOffset = document.getElementById('contact').offsetTop;

          const scrollPosition = window.scrollY;
    
          if (scrollPosition < servicesOffset) {
            setActiveSection('intro');
          } 
            else if (scrollPosition < contactOffset) {
            setActiveSection('services');
            }
            else {
            setActiveSection('contact');
          } 
        };
    
        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
    
        <nav class="tm-nav">
            <button className="navbar-toggler" type="button" aria-label="Toggle navigation">
                <i className="fas fa-bars"></i>
            </button>
            <ul id="tm-main-nav">
                <li class="nav-item">                                
                    <a href="#intro" class={activeSection === 'intro' ?"nav-link current" : " nav-link" }>
                        startura
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#services" class={activeSection === 'services' ?"nav-link current" : " nav-link" }>
                        Palvelut
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#contact" class={activeSection === 'contact' ?"nav-link current" : " nav-link" }>
                        Yhteystiedot
                    </a>
                </li>

            </ul>
        </nav>
    
    )
}